import React from "react"; 
import "./home.css";
import photo from "./images/jothsna.jpeg";

const Home = () => {
    return (
        <div className="h-container">
            <div className="h-wrapper"> 
                <div className="h-left">
                    <div className="title">
                        <div className="pink-circle"></div> 
                        <h1>Jothsna <br/> Guravana <br/> </h1>
                    </div> 
                    <div className="desc">
                       <span className="abtme">Hi ! I am a enthusastic web developer interested in Backend frameworks , NLP </span> 
                       <span className="abtme">NLP | Full Stack Development | Competitive Programming | DSA </span>
                    </div> 
                    <div className="butt">
                        {/* <button className="button">Resume</button> */}
                        <button className="button" onClick={() => window.open("https://clone1react.pages.dev/", "_blank")}>
                            Resume
                        </button>

                    </div>
                </div>
                <div className="h-right">
                    <div className="img-container">
                        <img src={photo} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;